











































import Vue from 'vue';
import { mapGetters } from 'vuex';
import Modal from './Modal.vue';
import { ABOUT_MODAL_KEY } from '../constants';

export default Vue.extend({
	name: 'About',
	components: {
		Modal,
	},
	data() {
		return {
			ABOUT_MODAL_KEY,
			modalBus: new Vue(),
		};
	},
	computed: {
		...mapGetters('settings', ['versionCli']),
	},
	methods: {
		closeDialog() {
			this.modalBus.$emit('close');
		},
	},
});
